import React from 'react'
import { Router, Route, Redirect } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { theme, ThemeProvider } from 'shared/theme'
import GlobalStyles from 'shared/theme/global-styles'
import TypedRoute from 'routing/TypedRoute'
import TypedSwitch from 'routing/TypedSwitch'
import history from 'routing/history'
import { ToastContainer } from 'react-toastify'

import Auth, { queryToHashSwap } from 'auth/Auth'

import Login from 'pages/Login'
import NotFound from 'pages/NotFound'
import Search from 'pages/Search'
import LockedTitles from 'pages/LockedTitles'
import Feature from 'pages/Feature'
import Series from 'pages/Series'
import Episode from 'pages/Episode'
import Season from 'pages/Season'
import WorkflowDetails from 'pages/WorkflowDetails'
import CreateWorkflowReport from 'pages/admin/workflow/create-report'
import CreateDescriptorsReport from 'pages/admin/workflow/create-descriptors-report'

import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'shared/errors/ErrorFallback'
import PageTitle from 'shared/components/PageTitle'
import LockedTitlesProvider from 'shared/LockedTitles/LockedTitlesProvider'
import BugReportProvider from 'shared/BugReport/BugReportProvider'
import Version from 'shared/components/util/Version'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from 'shared/queryClient'
import { flags } from 'shared/flags'

import {
    auth,
    featureParser,
    seriesParser,
    homeParser,
    lockedTitlesParser,
    searchParser,
    episodeParser,
    seasonParser,
    searchLink,
    adminCreateWorkflowAssignmentParser,
    adminListWorkflowAssignmentParser,
    listWorkflowAssignmentParser,
    workflowParser,
    createWorkflowReportParser,
    createDescriptorsReportParser,
} from 'routing/routes'
import { XStateInspectLoader } from 'xstate-helpers/react/XStateInspectLoader'
import CreateWorkflowAssignment from 'pages/admin/workflow/create-assignments'
import WorkflowsList from 'pages/admin/workflow/list'
import WorkflowAssignmentsList from 'pages/workflow/assignments-list'
import {
    PERMISSION_WRITE,
    PERMISSION_PUBLISH,
    PERMISSION_DEBUG,
} from '@genome-web-forms/common/auth'
import { LocationsHierarchy } from 'shared/components/LocationsHierarchy/LocationsHierarchy'
import { useHasPermission } from 'shared/components/UserHasPermission'
import { useNonAuthSnowplowPageView } from 'shared/hooks/useSnowPlowPageView'

// Router considers query params passed for the home page rout as invalid.
// That makes Not Found fallback component shown. If we replace query to hash before react app starts,
// it makes it working as usual.
queryToHashSwap()

function App(): React.ReactElement {
    return (
        <XStateInspectLoader>
            <QueryClientProvider client={queryClient}>
                <Auth history={history}>
                    <HelmetProvider>
                        <PageTitle />
                        <ThemeProvider theme={theme}>
                            <ToastContainer
                                position="top-center"
                                hideProgressBar={true}
                                newestOnTop={true}
                                closeOnClick={false}
                                draggable={false}
                                pauseOnHover={true}
                            />
                            <GlobalStyles />
                            <Router history={history}>
                                <BugReportProvider>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <LockedTitlesProvider>
                                            <LocationsHierarchy>
                                                <Routes />
                                            </LocationsHierarchy>
                                        </LockedTitlesProvider>
                                    </ErrorBoundary>
                                </BugReportProvider>
                            </Router>
                            <Version />
                        </ThemeProvider>
                    </HelmetProvider>
                </Auth>
                {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
            </QueryClientProvider>
        </XStateInspectLoader>
    )
}

function Routes(): React.ReactElement {
    const isAdmin = useHasPermission('DEBUG')

    return (
        <TypedSwitch authenticatingComponent={Login} notAuthorizedComponent={NotAuthorized}>
            <TypedRoute isPrivate={true} parser={homeParser}>
                <Redirect to={searchLink()} />
            </TypedRoute>

            {flags.workflows && (
                <TypedRoute
                    isPrivate={true}
                    parser={adminCreateWorkflowAssignmentParser}
                    component={CreateWorkflowAssignment}
                    permissions={[PERMISSION_DEBUG]}
                />
            )}

            {flags.workflows && (
                <TypedRoute
                    isPrivate={true}
                    parser={adminListWorkflowAssignmentParser}
                    component={WorkflowsList}
                    permissions={[PERMISSION_DEBUG]}
                />
            )}

            {flags.workflows && (
                <TypedRoute
                    isPrivate={true}
                    parser={listWorkflowAssignmentParser}
                    component={WorkflowAssignmentsList}
                    permissions={[PERMISSION_WRITE || PERMISSION_DEBUG || PERMISSION_PUBLISH]}
                />
            )}

            {flags.workflows && isAdmin && (
                <TypedRoute
                    isPrivate={true}
                    parser={createWorkflowReportParser}
                    component={CreateWorkflowReport}
                    permissions={[PERMISSION_DEBUG]}
                />
            )}

            {flags.workflows && isAdmin && (
                <TypedRoute
                    isPrivate={true}
                    parser={createDescriptorsReportParser}
                    component={CreateDescriptorsReport}
                    permissions={[PERMISSION_DEBUG]}
                />
            )}

            <TypedRoute isPrivate={true} parser={lockedTitlesParser} component={LockedTitles} />
            <TypedRoute isPrivate={true} parser={searchParser} component={Search} />
            <TypedRoute isPrivate={true} parser={featureParser} component={Feature} />
            <TypedRoute isPrivate={true} parser={seriesParser} component={Series} />
            <TypedRoute isPrivate={true} parser={episodeParser} component={Episode} />
            <TypedRoute isPrivate={true} parser={seasonParser} component={Season} />
            <TypedRoute isPrivate={true} parser={workflowParser} component={WorkflowDetails} />

            {/* must not be private */}
            <TypedRoute parser={auth.loginParser} component={Login} />

            {/* must be last */}
            <Route component={NotFound} />
        </TypedSwitch>
    )
}

const NotAuthorized: React.FC<{ permissions: string[] }> = ({ permissions }) => {
    useNonAuthSnowplowPageView('Not Authorized', 'Not Authorized Page')

    return (
        <div>
            <h1>Not authorized</h1>
            <p>
                You do not have permission to view this page. It requires the following permissions:
            </p>
            <pre>{JSON.stringify(permissions, null, 2)}</pre>
        </div>
    )
}

export default App
