import { MyIDUser } from '@genome-web-forms/common/auth'

type AuthValue = {
    authenticationPending: boolean
    signedIn: null | boolean
    user: null | MyIDUser
}
// Not used
export class Storage {
    /**
     * The underlying storage mechanism.
     * Can be localStorage, sessionStorage, undefined, in that order
     */
    static underlying = checkLocalStorageAccessible()
        ? window.localStorage
        : checkSessionStorageAccesible()
        ? window.sessionStorage
        : undefined
    static TOKEN_KEY = 'gwf-myid-access-token'
    static FAST_AUTH_KEY = 'gef-fast-auth-value'

    // used when storage is not accessible, at least we can run in-memory
    memoryToken: false | string = false

    setToken(token: string): void {
        if (Storage.underlying) {
            Storage.underlying.setItem(Storage.TOKEN_KEY, token)
        } else {
            this.memoryToken = token
        }
    }

    getToken(): string | false {
        if (Storage.underlying) {
            return Storage.underlying.getItem(Storage.TOKEN_KEY) ?? false
        } else {
            return this.memoryToken
        }
    }

    clearToken(): void {
        if (Storage.underlying) {
            Storage.underlying.removeItem(Storage.TOKEN_KEY)
        } else {
            this.memoryToken = false
        }
    }

    setAuthValue(authValue: AuthValue): void {
        if (Storage.underlying) {
            Storage.underlying.setItem(Storage.FAST_AUTH_KEY, JSON.stringify(authValue))
        }
    }

    getAuthValue(): AuthValue | null {
        if (Storage.underlying) {
            const authStr = Storage.underlying.getItem(Storage.FAST_AUTH_KEY)
            return authStr ? JSON.parse(authStr) : null
        }
        return null
    }

    clearAuthValue(): void {
        if (Storage.underlying) {
            Storage.underlying.removeItem(Storage.FAST_AUTH_KEY)
        }
    }
}

const storage = new Storage()
export default storage

// https://github.com/Modernizr/Modernizr/tree/master/feature-detects/storage
function checkSessionStorageAccesible(): boolean {
    const mod = 'modernizr'
    try {
        window.sessionStorage.setItem(mod, mod)
        window.sessionStorage.removeItem(mod)
        return true
    } catch (e) {
        return false
    }
}

function checkLocalStorageAccessible(): boolean {
    const mod = 'modernizr'
    try {
        localStorage.setItem(mod, mod)
        localStorage.removeItem(mod)
        return true
    } catch (e) {
        return false
    }
}
