import React, { useCallback } from 'react'
import { useField } from 'formik'
import ReactTooltip from 'react-tooltip'

import styled from 'shared/theme'

import { RoleName } from '@genome-web-forms/common/auth'
import {
    ROLE_NAME_TAGGER,
    ROLE_NAME_QA,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
    PartialMyIDUser,
} from '@genome-web-forms/server'

import { TaskType, TaskTypeOption } from 'model/workflow/TaskType'
import FormikSelect, { FormikSelectProps } from 'shared/components/Select/FormikSelect'
import { ReactComponent as Warning } from 'shared/components/Icons/warning-icon.svg'

export const StyledInfo = styled(Warning)`
    fill: ${props => props.theme.colors.primary};
    cursor: default;
    max-width: 1.25rem;
`

export const ASSIGNEE_TOOLTIP_TEXT = 'Characters tasks are available only for users with a DEI role'

export const AssigneeTooltip: React.FC<{ text: string; id: string }> = props => {
    return (
        <>
            <span
                data-tip
                data-for={props.id}
                style={{
                    padding: '0 0 0 5px',
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    display: 'inline-block',
                    top: '8px',
                }}
            >
                <StyledInfo></StyledInfo>
            </span>
            <ReactTooltip effect="solid" id={props.id}>
                {props.text}
            </ReactTooltip>
        </>
    )
}

type AssigneeOption = { value: string; label: string; disabled: boolean; data: { roles: string[] } }
type AssigneeSelectProps = FormikSelectProps<{ value: string; label: string }> & {
    tasks?: TaskType[]
    options: AssigneeOption[]
    isLoading: boolean
}

// For filtering on client to make sure that old assignments and their data displays correctly
const ALLOWED_USERS_LIST = [
    'VIERA021',
    'SANCC259',
    'ROBID162',
    'AVELE004',
    'GOLDE043',
    'MARSE041',
    'CAVAI007',
    'MIRAK021',
    'BATKK002',
    'HUCKL005',
    'MILLM501',
    'MIRCM003',
    'HALLN044',
    'MORES103',
    'COSTS046',
    'PERSS019',
    'PERSS020',
    'ELLIS108',
    'MATHA084',
    'MISHI003',
]

export const getAsigneeOptions = (
    users: PartialMyIDUser[] = [],
    tasks: TaskType[] = [],
): AssigneeOption[] => {
    const hasCharacterTasks = tasks.includes(WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS)
    return users
        .filter(pu =>
            pu.roles.some(role => role.name === ROLE_NAME_TAGGER || role.name === ROLE_NAME_QA),
        )
        .filter(pu => ALLOWED_USERS_LIST.includes(pu['relationship.employeeId']))
        .map(pu => {
            const roleNames = pu.roles.map(r => r.name)
            const isDisabled = hasCharacterTasks && !roleNames.includes(RoleName.DEI)
            let option = {
                value: pu['relationship.employeeId'],
                label: `${pu.given_name} ${pu.family_name} (${pu['relationship.employeeId']})`,
                data: { roles: roleNames },
                disabled: isDisabled,
            }
            return option
        })
}

export const getTasksOptions = (
    taskTypeOptions: TaskTypeOption[],
    assigneeOptions: AssigneeOption[],
    assignee: string | null,
): TaskTypeOption[] => {
    const selectedUserOption = assigneeOptions.find(i => i.value === assignee)
    let res = taskTypeOptions
    if (selectedUserOption && !selectedUserOption.data.roles.includes(RoleName.DEI)) {
        res = taskTypeOptions.filter(
            tt => tt.value !== WORKFLOW_CREATIVE_WORK_TAGGING_TASK_CHARACTERS,
        )
    }
    return res
}

const AssigneeSelect: React.FC<AssigneeSelectProps> = ({ tasks, options, isLoading, ...props }) => {
    const [{ value }, , { setValue }] = useField<string | null>(props.name)

    const isOptionDisabled = useCallback(
        (option: {
            value: string
            label: string
            disabled: boolean
            data: { roles: string[] }
        }) => {
            return option.disabled
        },
        [],
    )

    return (
        <FormikSelect<any>
            {...props}
            options={options}
            isLoading={isLoading}
            isClearable={true}
            value={options.find(o => o.value === value)}
            isOptionDisabled={isOptionDisabled}
            onChange={
                ((value?: { value: string } | null) => setValue(value ? value.value : null)) as any
            }
        />
    )
}

export default AssigneeSelect
