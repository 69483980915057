import React from 'react'
import { ActionType, getType, createAction } from 'typesafe-actions'
import * as E from 'fp-ts/lib/Either'
import { useContextHelper } from 'shared/hooks/useContextHelper'
import { notification } from 'shared/notification'
import storage from 'auth/Storage'
import config from 'shared/config'
import {
    AuthResponse,
    AuthResponseCodec,
    MaestroUserData,
    MaestroUserDataCodec,
    MyIDUser,
} from '@genome-web-forms/common/auth'
import BackendPrimer from 'shared/BackendPrimer'
import { isAxiosError } from '@genome-web-forms/common/error'
import qs from 'query-string'

import isString from 'lodash/isString'
import history from 'routing/history'
import { History } from 'history'
import { getSnowplowUser, setUserId } from 'shared/util/snowplowUtils'

const debug = require('debug')('gwf.auth') // eslint-disable-line

interface AuthenticationPending {
    authenticationPending: true
    signedIn: null
    user: null
}

interface NotSignedIn {
    authenticationPending: false
    signedIn: false
    user: null
}

interface SignedIn {
    authenticationPending: false
    signedIn: true
    user: MyIDUser
}

type AuthValueActual = AuthenticationPending | NotSignedIn | SignedIn

// pretend user always exists for the benefit of context users
export type AuthValue =
    | {
          authenticationPending: true
          signedIn: undefined
          user: MyIDUser
      }
    | {
          authenticationPending: false
          signedIn: false
          user: MyIDUser
      }
    | {
          authenticationPending: false
          signedIn: true
          user: MyIDUser
      }

type ParseUserType = {
    maybeUser: E.Either<any, MaestroUserData>
    authResponse: AuthResponse
}

export const actions = {
    setAuthenticationPending: createAction('AUTH/SET_AUTHENTICATION_PENDING')(),
    signin: createAction('AUTH/SIGNIN')<{
        user: MyIDUser
    }>(),
    signout: createAction('AUTH/SIGNOUT')(),
}

const reducer = (_: AuthValueActual, action: ActionType<typeof actions>): AuthValueActual => {
    switch (action.type) {
        case getType(actions.setAuthenticationPending): {
            return {
                authenticationPending: true,
                signedIn: null,
                user: null,
            }
        }

        case getType(actions.signin): {
            const { user } = action.payload

            BackendPrimer.prime(user)

            const snowplowUser = getSnowplowUser(user)

            setUserId(snowplowUser.email)

            return {
                authenticationPending: false,
                signedIn: true,
                user,
            }
        }

        case getType(actions.signout): {
            storage.clearAuthValue()

            return {
                authenticationPending: false,
                signedIn: false,
                user: null,
            }
        }
    }
}

export const AuthContext = React.createContext<AuthValue>(null as any)
AuthContext.displayName = 'Auth'
export const AuthDispatchContext = React.createContext<React.Dispatch<ActionType<typeof actions>>>(
    null as any,
)
AuthDispatchContext.displayName = 'AuthDispatch'
export function useAuthContext(): AuthValue {
    return useContextHelper(AuthContext, 'AuthContext')
}
export function useUser(): MyIDUser {
    const { user } = useAuthContext()
    if (!user) {
        throw new Error(
            `MyIDUser not available. You're probably using "useUser()" in a context where unauthenticated users have access.`,
        )
    }

    return user
}

export const queryToHashSwap = (): void => {
    const search = history.location.search
    if (search && window.location.pathname === '/') {
        history.replace({
            ...history.location,
            hash: search.substring(1),
            search: '',
        })
    }
}

type MyIDHashResponse = E.Either<{ error: string; error_description?: string }, { code?: string }>

export function consumeMyIDHash(history: History): MyIDHashResponse {
    const hash = history.location.hash

    const parsed = qs.parse(hash)
    debug('parsed hash', parsed)

    const { error, error_description, code, state } = parsed
    if (isString(error)) {
        return E.left({
            error,
            error_description: error_description as string | undefined,
        })
    }

    let returnTo: string | undefined
    try {
        returnTo = JSON.parse(state as string).returnTo
    } catch {
        returnTo = qs.parse(history.location.search).returnTo as string | undefined
    }

    // go to returnTo url if present
    if (returnTo) {
        history.replace(returnTo)
    } else {
        // remove #hash from window.location
        history.replace({
            ...history.location,
            hash: '',
        })
    }

    if (!isString(code)) {
        return E.right({})
    }

    return E.right({ code })
}

declare global {
    // eslint-disable-next-line no-var
    var __EARLY_AUTH_FETCH: Promise<unknown> | undefined
}

type AuthProps = {
    history?: History
}

const buildHeadersForUser = (userData: MaestroUserData, authData: AuthResponse): MyIDUser => {
    // Normalize data so it is compatible with PartialUser that is used by API
    const udata: MaestroUserData = { ...userData }
    if (udata.roles) {
        udata.roles = udata.roles.map(r => {
            if (!r.attributes) {
                r.attributes = []
            }
            if (!r.functionalAbilities) {
                r.functionalAbilities = []
            }
            return r
        })
    }

    // if(udata.rolesFullAssertion) {
    //     delete udata.rolesFullAssertion
    // }

    const res = {
        ...(udata as any),
        'x-gsso-myid': authData.access_token,
        'x-gsso-myid-refresh-token': authData.refresh_token,
        'trs-authz-token': authData['trs-authz-token'],
        'cwr-api-authz-token': authData['cwr-api-authz-token'],
        id_token: authData.id_token,
    }

    return res
}

const Auth: React.FC<AuthProps> = ({ children, history: outerHistory }): React.ReactElement => {
    const [authValue, dispatch] = React.useReducer(reducer, {
        authenticationPending: true,
        signedIn: null,
        user: null,
    })

    React.useEffect(() => {
        try {
            const v = storage.getAuthValue()
            if (v?.signedIn) {
                return dispatch(actions.signin(v as SignedIn))
            }
        } catch (e) {}

        const accessDataEither = consumeMyIDHash(outerHistory ?? history)
        debug('accessData', accessDataEither)

        if (E.isLeft(accessDataEither)) {
            // const { error, error_description } = accessDataEither.left

            notification.error(
                <>
                    <strong>MyID authentication failed!</strong>
                    <br />
                    <br />
                    {/* <strong>{error}:</strong> {error_description} */}
                    <span>Internal MyID error. Please try again.</span>
                </>,
            )

            return dispatch(actions.signout())
        }

        const code = accessDataEither.right.code || ''

        const earlyFetch = window.__EARLY_AUTH_FETCH

        if (!code) {
            dispatch(actions.signout())
            return
        }

        const handleAuthResponse = (authRespPromise: Promise<any>) => {
            return authRespPromise
                .then(authData => {
                    const authDataDecoded = AuthResponseCodec.decode(authData)

                    if (E.isRight(authDataDecoded)) {
                        const maybeUser = MaestroUserDataCodec.decode(
                            JSON.parse(atob(authDataDecoded.right.user_data)),
                        )
                        return {
                            maybeUser,
                            authResponse: authDataDecoded.right,
                        }
                    } else {
                        throw new Error('invalid auth data')
                    }
                })
                .then(parseUser)
                .catch(() => {
                    dispatch(actions.signout())
                })
        }

        // Non Local Environments
        if (earlyFetch) {
            window.__EARLY_AUTH_FETCH = undefined
            handleAuthResponse(earlyFetch)
            return void 0
        }

        // Local dev environment
        const inferredHost = `${window.location.protocol}//${window.location.host}`
        const host = process.env.PUBLIC_URL || inferredHost

        const devFetch = fetch(
            config.urlAuth +
                '?' +
                new URLSearchParams({
                    code: code,
                    redirectUri: host,
                }).toString(),
            {},
        ).then(r => r.json())

        handleAuthResponse(devFetch)

        let stopped = false
        return () => {
            stopped = true
        }
        ///////////////////////////////

        function parseUser(parseData: ParseUserType): void {
            const maybeUser = parseData.maybeUser
            const authData = parseData.authResponse

            if (stopped) {
                return
            }

            if (E.isRight(maybeUser)) {
                const user = buildHeadersForUser(maybeUser.right, authData)
                // TODO: Remove this after roles related logic is settled/tested
                // user.roles = [
                //     { name: 'Access Dev', functionalAbilities: [], attributes: [] },
                //     { name: 'Access Prod', functionalAbilities: [], attributes: [] },
                //     { name: 'Access QA', functionalAbilities: [], attributes: [] },
                //     { name: 'Access Staging', functionalAbilities: [], attributes: [] },
                //     {
                //         name: 'GWF Read Only',
                //         functionalAbilities: [
                //             { securedEntities: [], name: 'READ', attributes: [] },
                //         ],
                //         attributes: [],
                //     },
                //     { name: 'Library Read', functionalAbilities: [], attributes: [] },
                //     { name: 'Library Write', functionalAbilities: [], attributes: [] },
                //     {
                //         name: 'DEI',
                //         functionalAbilities: [
                //             { securedEntities: [], name: 'WRITE DEI', attributes: [] },
                //         ],
                //         attributes: [],
                //     },
                //     {
                //         name: 'GWF Tag+Publish',
                //         functionalAbilities: [
                //             { securedEntities: [], name: 'WRITE', attributes: [] },
                //             { securedEntities: [], name: 'READ', attributes: [] },
                //             { securedEntities: [], name: 'PUBLISH', attributes: [] },
                //         ],
                //         attributes: [],
                //     },
                // ] as any

                if (!user.roles) {
                    notification.error(
                        <>
                            The user {user['relationship.employeeId']} does not have acces to Genome
                            Web Forms.
                            <br />
                            If you believe this is a mistake, contact your administrator about
                            adding Keystone Roles for Genome Web Forms access to the user{' '}
                            {user['relationship.employeeId']}.
                        </>,
                    )

                    return dispatch(actions.signout())
                }

                return dispatch(actions.signin({ user }))
            }

            const error = maybeUser.left

            if (isAxiosError(error)) {
                dispatch(actions.signout())
                return void 0
            }

            throw error
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Saving this data to avoid auth call for each page load since "code" is expiring after 5 minutes
    React.useEffect(() => {
        try {
            storage.setAuthValue(authValue)
        } catch (e) {}
    }, [authValue])

    return (
        <AuthContext.Provider value={authValue as AuthValue}>
            <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
        </AuthContext.Provider>
    )
}

export default Auth
